.skip-to-content {
  position: fixed;
  top: 12px;
  left: auto;
  right: 1rem;
}
.skip-button {
  opacity: 0.01;
  transform: translateY(-200%);
}
.skip-button:focus {
  opacity: 1;
  transform: translateY(0);
}

/*
  The search button is rendered as a Material UI button for which we override the styles: this hack
  allows to align the search button with the other elements in the sidebar.
*/
.search-icon {
  text-transform: none !important;
  letter-spacing: normal !important;
  min-width: auto !important;
}
